/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Check, ChevronRight, Phone, Mail, Calendar, Building2, Compass, Building, Ruler, Hammer, ClipboardList } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { OptimizedImage } from '../components/OptimizedImage';

// Service-specific content
const serviceDetails = {
  'architecture': {
    title: 'Architecture Services',
    icon: Building2,
    heroDescription: 'Transforming visions into stunning, functional spaces that inspire and endure.',
    mainDescription: `Our architecture service combines creativity, technical expertise, and sustainable practices 
    to deliver exceptional designs that exceed expectations. We work closely with clients throughout the entire 
    process, from initial concept to final construction.`,
    processSteps: [
      {
        title: 'Initial Consultation',
        description: 'We begin by understanding your vision, requirements, and project goals.'
      },
      {
        title: 'Concept Development',
        description: 'Our team creates initial designs and concepts based on your input and our expertise.'
      },
      {
        title: 'Design Refinement',
        description: 'We refine the designs based on your feedback and technical considerations.'
      },
      {
        title: 'Technical Documentation',
        description: 'Detailed plans and specifications are prepared for construction.'
      },
      {
        title: 'Construction Support',
        description: 'We provide oversight during construction to ensure design integrity.'
      }
    ],
    benefits: [
      'Innovative and functional designs',
      'Sustainable and energy-efficient solutions',
      'Seamless integration with surroundings',
      'Compliance with regulations and standards',
      'Enhanced property value'
    ],
    expertise: [
      'Residential Architecture',
      'Commercial Buildings',
      'Industrial Facilities',
      'Interior Architecture',
      'Sustainable Design',
      'Historic Preservation'
    ],
    faqs: [
      {
        question: 'How long does the architectural design process typically take?',
        answer: 'The timeline varies depending on project scope and complexity, typically ranging from 3-6 months for design development.'
      },
      {
        question: 'Do you handle all necessary permits and approvals?',
        answer: 'Yes, we manage the entire permit process, ensuring compliance with local regulations and building codes.'
      },
      {
        question: 'Can you work with existing structures or renovations?',
        answer: 'Absolutely! We have extensive experience in both new construction and renovation projects.'
      }
    ]
  },
  'engineering': {
    title: 'Engineering Services',
    icon: Compass,
    heroDescription: 'Innovative engineering solutions that bring your projects to life with precision and excellence.',
    mainDescription: `Our engineering services combine cutting-edge technology with proven methodologies to deliver 
    robust, efficient, and sustainable solutions. We provide comprehensive engineering expertise across structural, 
    mechanical, electrical, and plumbing disciplines.`,
    processSteps: [
      {
        title: 'Requirements Analysis',
        description: 'We thoroughly analyze project requirements and constraints.'
      },
      {
        title: 'Technical Design',
        description: 'Our team develops detailed technical designs and specifications.'
      },
      {
        title: 'Analysis and Optimization',
        description: 'We perform comprehensive analysis to optimize designs for efficiency and safety.'
      },
      {
        title: 'Documentation',
        description: 'Detailed engineering drawings and specifications are prepared.'
      },
      {
        title: 'Implementation Support',
        description: 'We provide technical support during the construction phase.'
      }
    ],
    benefits: [
      'Optimized structural integrity',
      'Energy-efficient systems design',
      'Cost-effective solutions',
      'Compliance with engineering standards',
      'Innovative problem-solving approaches'
    ],
    expertise: [
      'Structural Engineering',
      'MEP Engineering',
      'Civil Engineering',
      'Geotechnical Engineering',
      'Environmental Engineering',
      'Safety Engineering'
    ],
    faqs: [
      {
        question: 'What types of engineering services do you offer?',
        answer: 'We offer a full range of engineering services including structural, mechanical, electrical, plumbing, and civil engineering.'
      },
      {
        question: 'How do you ensure the safety and reliability of your designs?',
        answer: 'We follow strict industry standards and use advanced modeling software to validate our designs. All our work undergoes rigorous peer review.'
      },
      {
        question: 'Can you handle both small and large-scale engineering projects?',
        answer: 'Yes, our team is equipped to handle projects of all sizes, from small residential to large commercial and industrial projects.'
      }
    ]
  },
  'town-planning': {
    title: 'Town Planning Services',
    icon: Building,
    heroDescription: 'Shaping sustainable communities through strategic urban planning and development.',
    mainDescription: `Our town planning services focus on creating vibrant, sustainable communities that balance 
    growth with quality of life. We provide comprehensive planning solutions that consider environmental, social, 
    and economic factors.`,
    processSteps: [
      {
        title: 'Site Analysis',
        description: 'Comprehensive assessment of site conditions and surroundings.'
      },
      {
        title: 'Planning Strategy',
        description: 'Development of strategic planning approaches aligned with local regulations.'
      },
      {
        title: 'Stakeholder Engagement',
        description: 'Consultation with community members and relevant stakeholders.'
      },
      {
        title: 'Plan Development',
        description: 'Creation of detailed planning documents and visualizations.'
      },
      {
        title: 'Approval Process',
        description: 'Management of planning applications and approval procedures.'
      }
    ],
    benefits: [
      'Sustainable community development',
      'Optimized land use planning',
      'Enhanced urban design',
      'Regulatory compliance',
      'Community-focused solutions'
    ],
    expertise: [
      'Master Planning',
      'Urban Design',
      'Zoning Analysis',
      'Environmental Planning',
      'Transportation Planning',
      'Community Development'
    ],
    faqs: [
      {
        question: 'How long does the town planning process typically take?',
        answer: 'The timeline varies depending on project scope and local regulations, typically ranging from 6-18 months.'
      },
      {
        question: 'Do you handle all necessary planning permits and approvals?',
        answer: 'Yes, we manage the entire planning approval process, including liaising with local authorities.'
      },
      {
        question: 'How do you ensure community needs are considered in your plans?',
        answer: 'We conduct thorough community engagement and stakeholder consultations throughout the planning process.'
      }
    ]
  },
  'quantity-surveying': {
    title: 'Quantity Surveying Services',
    icon: Ruler,
    heroDescription: 'Precision cost management and financial control for construction projects.',
    mainDescription: `Our quantity surveying services provide accurate cost estimation, budgeting, and financial 
    control throughout the entire project lifecycle. We ensure optimal resource allocation and cost-effectiveness 
    while maintaining quality standards.`,
    processSteps: [
      {
        title: 'Initial Cost Planning',
        description: 'Development of preliminary cost estimates and budgets.'
      },
      {
        title: 'Tender Documentation',
        description: 'Preparation of detailed bills of quantities and tender documents.'
      },
      {
        title: 'Cost Monitoring',
        description: 'Ongoing monitoring and control of project costs.'
      },
      {
        title: 'Contract Administration',
        description: 'Management of contractual matters and payment certifications.'
      },
      {
        title: 'Final Account',
        description: 'Preparation and settlement of final project accounts.'
      }
    ],
    benefits: [
      'Accurate cost estimation',
      'Effective budget management',
      'Risk mitigation',
      'Value engineering',
      'Contractual expertise'
    ],
    expertise: [
      'Cost Planning',
      'Tender Management',
      'Contract Administration',
      'Value Engineering',
      'Risk Management',
      'Life Cycle Costing'
    ],
    faqs: [
      {
        question: 'How do you ensure accuracy in cost estimations?',
        answer: 'We use industry-standard software and maintain an extensive database of current market rates, combined with our expertise and experience.'
      },
      {        question: 'What role does a quantity surveyor play during the construction phase?',
        answer: 'A quantity surveyor provides financial control and contractual management throughout the construction process, ensuring the project remains within budget and contractual obligations are met.'
      },
      {
        question: 'Do you offer post-construction services?',
        answer: 'Yes, we manage the preparation and settlement of final project accounts, ensuring all financial aspects are closed out properly.'
      }
    ]
  },
    'project-management': {
    title: 'Project Management Services',
    icon: ClipboardList,
    heroDescription: 'Delivering projects on time, within budget, and exceeding expectations through strategic planning and execution.',
    mainDescription: `Our project management services ensure seamless coordination and successful delivery of 
    construction projects of all sizes. We combine proven methodologies with cutting-edge tools to optimize 
    resources, mitigate risks, and achieve project objectives efficiently.`,
    processSteps: [
      {
        title: 'Project Initiation',
        description: 'Define project scope, objectives, and key stakeholders to set a solid foundation.'
      },
      {
        title: 'Planning & Strategy',
        description: 'Develop comprehensive project plans, schedules, and resource allocation strategies.'
      },
      {
        title: 'Execution & Coordination',
        description: 'Implement plans while coordinating teams, resources, and activities effectively.'
      },
      {
        title: 'Monitoring & Control',
        description: 'Track progress, manage changes, and ensure adherence to quality standards and timelines.'
      },
      {
        title: 'Closure & Evaluation',
        description: 'Successfully close the project and conduct post-project analysis for continuous improvement.'
      }
    ],
    benefits: [
      'Efficient resource utilization',
      'Risk identification and mitigation',
      'Enhanced stakeholder communication',
      'Quality assurance and control',
      'On-time and within-budget delivery'
    ],
    expertise: [
      'Construction Project Management',
      'Program Management',
      'Risk Management',
      'Stakeholder Management',
      'Change Management',
      'Value Engineering'
    ],
    faqs: [
      {
        question: 'What project management methodologies do you use?',
        answer: 'We employ a blend of traditional and agile methodologies, tailored to each projects specific needs and requirements.'
      },
      {
        question: 'How do you handle project delays and budget overruns?',
        answer: 'We use proactive risk management strategies and robust monitoring systems to identify potential issues early and implement mitigation measures promptly.'
      },
      {
        question: 'Can you manage multiple projects simultaneously?',
        answer: 'Yes, our experienced team and advanced project management tools enable us to effectively manage multiple projects while ensuring individual attention to each.'
      }
    ]
  },
  'construction': {
    title: 'Construction Services',
    icon: Hammer,
    heroDescription: 'Building excellence through quality craftsmanship and project management.',
    mainDescription: `Our construction services are designed to bring your vision to life with a focus on quality, 
    efficiency, and safety. We offer a full range of construction solutions from planning to project completion.`,
    processSteps: [
      {
        title: 'Project Planning',
        description: 'Detailed project schedules and work plans are developed to ensure timely execution.'
      },
      {
        title: 'Site Preparation',
        description: 'We handle all aspects of site preparation, including clearing and grading.'
      },
      {
        title: 'Construction Execution',
        description: 'Our team executes the construction process with precision and adherence to the highest standards.'
      },
      {
        title: 'Quality Control',
        description: 'Regular inspections and quality checks are conducted to ensure the project meets all requirements.'
      },
      {
        title: 'Project Handover',
        description: 'Final inspections are completed, and the project is handed over to the client.'
      }
    ],
    benefits: [
      'On-time project delivery',
      'High-quality workmanship',
      'Safety-focused operations',
      'Transparent project management',
      'Seamless collaboration with all stakeholders'
    ],
    expertise: [
      'Residential Construction',
      'Commercial Construction',
      'Industrial Construction',
      'Renovations and Remodeling',
      'Project Management',
      'Safety Management'
    ],
    faqs: [
      {
        question: 'What types of construction projects do you handle?',
        answer: 'We handle all types of construction projects, including residential, commercial, and industrial buildings.'
      },
      {
        question: 'How do you ensure the quality of your construction work?',
        answer: 'We follow strict quality control protocols throughout the construction process, with regular inspections and adherence to industry standards.'
      },
      {
        question: 'Can you manage the entire construction project from start to finish?',
        answer: 'Yes, we offer end-to-end project management services, from planning and design to construction and handover.'
      }
    ]
  }
};

const Modal = ({ isOpen, onClose, children, title }) => {
  if (!isOpen) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center p-4"
        onClick={onClose}
      >
        <motion.div
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          className="bg-white rounded-lg p-6 w-full max-w-md"
          onClick={e => e.stopPropagation()}
        >
          <h2 className="text-2xl font-bold mb-4">{title}</h2>
          {children}
          <button
            onClick={onClose}
            className="mt-4 w-full bg-gray-200 text-gray-800 py-2 px-4 rounded hover:bg-gray-300 transition duration-200"
          >
            Close
          </button>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

// Modal Components
const PhoneModal = ({ isOpen, setIsOpen }) => {
  if (!isOpen) return null;
  
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
      onClick={() => setIsOpen(false)}
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        onClick={e => e.stopPropagation()}
        className="bg-white rounded-xl p-6 max-w-md w-full"
      >
        <h3 className="text-2xl font-bold text-blue-800 mb-4">Contact by Phone</h3>
        <p className="text-gray-600 mb-6">Call us directly at:</p>
        <a 
          href="tel:+1234567890" 
          className="flex items-center justify-center gap-2 bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Phone className="w-5 h-5" />
          +263 776 347 735
        </a>
      </motion.div>
    </motion.div>
  );
};

const EmailModal = ({ isOpen, setIsOpen }) => {
  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
      onClick={() => setIsOpen(false)}
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        onClick={e => e.stopPropagation()}
        className="bg-white rounded-xl p-6 max-w-md w-full"
      >
        <h3 className="text-2xl font-bold text-blue-800 mb-4">Contact by Email</h3>
        <p className="text-gray-600 mb-6">Send us an email at:</p>
        <a 
          href="mailto:info@example.com" 
          className="flex items-center justify-center gap-2 bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors"
        >
          <Mail className="w-5 h-5" />
          info@build-fast.co.zw
        </a>
      </motion.div>
    </motion.div>
  );
};

const ScheduleModal = ({ isOpen, setIsOpen }) => {
  if (!isOpen) return null;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50"
      onClick={() => setIsOpen(false)}
    >
      <motion.div
        initial={{ scale: 0.95, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.95, opacity: 0 }}
        onClick={e => e.stopPropagation()}
        className="bg-white rounded-xl p-6 max-w-md w-full"
      >
        <h3 className="text-2xl font-bold text-blue-800 mb-4">Schedule a Consultation</h3>
        <p className="text-gray-600 mb-6">
          Please fill out the form below to schedule your consultation:
        </p>
        <form className="space-y-4">
          <div>
            <label className="block text-gray-700 mb-2">Name</label>
            <input 
              type="text" 
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Your name"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Email</label>
            <input 
              type="email" 
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="your@email.com"
            />
          </div>
          <div>
            <label className="block text-gray-700 mb-2">Preferred Date</label>
            <input 
              type="date" 
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <button 
            type="submit"
            className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
          >
            <Calendar className="w-5 h-5" />
            Schedule Now
          </button>
        </form>
      </motion.div>
    </motion.div>
  );
};

export const ServicePage = ({title}) => {
  const { serviceId } = useParams();
  const [phoneModalOpen, setPhoneModalOpen] = useState(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);

  const service = serviceDetails[serviceId];

  useEffect(() => {
    document.title = title;
  }, [title]);

  if (!service) {
    return <div>Service not found</div>;
  }

  const ServiceIcon = service.icon;

  return (
    <main className="min-h-screen bg-white">
      <Helmet>
        <title>{serviceDetails[serviceId].title} - BuildFast Structural Solutions</title>
        <meta name="description" content={serviceDetails[serviceId].mainDescription.substring(0, 160)} />
        <meta property="og:title" content={`${serviceDetails[serviceId].title} - BuildFast Structural Solutions`} />
        <meta property="og:description" content={serviceDetails[serviceId].mainDescription.substring(0, 160)} />
        <meta property="og:url" content={`https://build-fast.co.zw/services/${serviceId}`} />
        <link rel="canonical" href={`https://build-fast.co.zw/services/${serviceId}`} />
      </Helmet>

      {/* Hero Section */}
      <section className="relative py-24 bg-gradient-to-br from-blue-800 to-blue-900 overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-400/20 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-white/10 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>

        <div className="relative max-w-7xl mx-auto px-4 text-center z-10">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="flex flex-col items-center gap-6"
          >
            <ServiceIcon className="w-16 h-16 text-yellow-400 mb-4" />
            <motion.h1
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="text-4xl md:text-6xl font-bold text-white mb-6"
            >
              {service.title}
            </motion.h1>
            <motion.p
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              className="text-xl text-gray-200 max-w-3xl mx-auto"
            >
              {service.heroDescription}
            </motion.p>
          </motion.div>
        </div>
      </section>

      {/* Main Content */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
            {/* Left Column */}
            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-3xl font-bold text-blue-800 mb-6">Our Approach</h2>
              <div className="h-1 w-20 bg-yellow-400 mb-6"></div>
              <p className="text-gray-600 mb-8">{service.mainDescription}</p>
              
              <h3 className="text-2xl font-bold text-blue-800 mb-6">Our Process</h3>
              <div className="space-y-6">
                {service.processSteps.map((step, index) => (
                  <motion.div
                    key={index}
                    initial={{ x: -20, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    className="flex items-start"
                  >
                    <div className="flex-shrink-0 h-8 w-8 rounded-full bg-blue-600 text-white flex items-center justify-center mt-1">
                      {index + 1}
                    </div>
                    <div className="ml-4">
                      <h4 className="text-lg font-semibold text-blue-800">{step.title}</h4>
                      <p className="text-gray-600 mt-2">{step.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </motion.div>

            {/* Right Column */}
            <motion.div
              initial={{ x: 50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5 }}
              className="space-y-8"
            >
              {/* Key Benefits */}
              <div className="bg-white rounded-xl shadow-lg p-8">
                <h3 className="text-2xl font-bold text-blue-800 mb-6">Key Benefits</h3>
                <ul className="space-y-4">
                  {service.benefits.map((benefit, index) => (
                    <motion.li
                      key={index}
                      initial={{ x: 20, opacity: 0 }}
                      whileInView={{ x: 0, opacity: 1 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      className="flex items-center"
                    >
                      <Check className="w-5 h-5 text-green-500 mr-3 flex-shrink-0" />
                      <span className="text-gray-700">{benefit}</span>
                    </motion.li>
                  ))}
                </ul>
              </div>

              {/* Areas of Expertise */}
              <div className="bg-white rounded-xl shadow-lg p-8">
                <h3 className="text-2xl font-bold text-blue-800 mb-6">Areas of Expertise</h3>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {service.expertise.map((item, index) => (
                    <motion.div
                      key={index}
                      initial={{ y: 20, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      className="flex items-center"
                    >
                      <ChevronRight className="w-4 h-4 text-blue-600 mr-2 flex-shrink-0" />
                      <span className="text-gray-700">{item}</span>
                    </motion.div>
                  ))}
                </div>
              </div>

              {/* Service Image */}
              <div className="relative h-80 rounded-xl overflow-hidden shadow-lg">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full h-full object-cover"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-blue-900/50 to-transparent"></div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* FAQs Section */}
      <section className="py-16 bg-gradient-to-br from-gray-50 to-white relative overflow-hidden">
        <div className="absolute inset-0 opacity-10">
          <div className="absolute top-0 left-0 w-96 h-96 bg-blue-800 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-yellow-400 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-blue-800 mb-4">Frequently Asked Questions</h2>
            <div className="h-1 w-20 bg-yellow-400 mx-auto"></div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {service.faqs.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ y: 20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
              >
                <h3 className="text-xl font-semibold text-blue-800 mb-3">{faq.question}</h3>
                <p className="text-gray-600">{faq.answer}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="py-16 bg-gradient-to-br from-blue-800 to-blue-900 relative overflow-hidden">
        <div className="absolute inset-0">
          <div className="absolute top-0 left-0 w-96 h-96 bg-yellow-400/20 rounded-full filter blur-3xl -translate-x-1/2 -translate-y-1/2"></div>
          <div className="absolute bottom-0 right-0 w-96 h-96 bg-white/10 rounded-full filter blur-3xl translate-x-1/2 translate-y-1/2"></div>
        </div>

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10">
          <motion.div
            initial={{ y: 20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            className="space-y-8"
          >
            <h2 className="text-3xl font-bold text-white mb-8">Ready to Get Started?</h2>
            <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
              <button
                onClick={() => setPhoneModalOpen(true)}
                className="flex items-center justify-center bg-white/10 text-white px-8 py-4 rounded-xl 
                         hover:bg-white/20 transition duration-300 w-full md:w-auto group"
              >
                <Phone className="w-5 h-5 mr-2" />
                Call Us
                <ChevronRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
              </button>

              <button
                onClick={() => setEmailModalOpen(true)}
                className="flex items-center justify-center bg-white/10 text-white px-8 py-4 rounded-xl 
                         hover:bg-white/20 transition duration-300 w-full md:w-auto group"
              >
                <Mail className="w-5 h-5 mr-2" />
                Email Us
                <ChevronRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
              </button>

              <button
                onClick={() => setScheduleModalOpen(true)}
                className="flex items-center justify-center bg-yellow-400 text-blue-800 px-8 py-4 rounded-xl 
                         hover:bg-yellow-300 transition duration-300 w-full md:w-auto group"
              >
                <Calendar className="w-5 h-5 mr-2" />
                Schedule Consultation
                <ChevronRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
              </button>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Contact Modals */}
      <PhoneModal isOpen={phoneModalOpen} setIsOpen={setPhoneModalOpen} />
      <EmailModal isOpen={emailModalOpen} setIsOpen={setEmailModalOpen} />
      <ScheduleModal isOpen={scheduleModalOpen} setIsOpen={setScheduleModalOpen} />
    </main>
  );
};
